import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";
import AdminLayout from "./component/adminDashboard/AdminLayout";
import AllTransaction from "./page/admin/AllTransaction";
import Distribution from "./page/admin/Distribution";
import Login from "./page/login/Login";
import Rewards from "./page/admin/Rewards";
import PageNotFound from "./page/PageNotFound";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" element={<AdminLayout />}>
            {/* <Route path="" element={<AdminIndex />}>
              <Route index element={<AllTransaction />} />
              <Route
                path="pendingTransaction"
                element={<PendingTransaction />}
              />
              <Route
                path="confirmTransaction"
                element={<ConfirmTransaction />}
              />
            </Route> */}
            <Route path="" element={<AllTransaction />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="distribution" element={<Distribution />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-right" reverseOrder={true} />
    </>
  );
}

export default App;
