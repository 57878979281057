import React from "react";

const Distribution = () => {
  const data = [
    { name: "TOTAL_SUPPLY", quantity: "1,000,000,000" },
    { name: "INITIAL_CIRCULATING_SUPPLY", quantity: "100,000,000" },
    { name: "LIQUIDITY_SUPPLY", quantity: "150,000,000" },
    { name: "COMMUNITY_AIRDROP_SUPPLY", quantity: "10,000,000" },
    { name: "TEAM_SUPPLY", quantity: "70,000,000" },
    { name: "ADVISORS_SUPPLY", quantity: "70,000,000" },
    { name: "ECOSYSTEM_GROWTH_SUPPLY", quantity: "100,000,000" },
    { name: "MARKETING_SUPPLY", quantity: "100,000,000" },
    { name: "DIVIDEND_REWARDS_SUPPLY", quantity: "400,000,000" },
  ];

  return (
    <div className="">
      <div className="bg-white shadow-custom-light py-6 px-4 rounded-[10px]">
        <div className="overflow-auto">
          <table className="w-full">
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#9999A11A]" : "bg-white"
                  }`}
                >
                  <td
                    className={`px-4 text-[#0A0A0A] text-[16px] leading-[19px] ${
                      index % 2 !== 0 ? "py-4" : "py-2"
                    }`}
                  >
                    {item.name}
                  </td>
                  <td
                    className={`px-4 text-[#0A0A0A] text-[16px] leading-[19px] ${
                      index % 2 !== 0 ? "py-4" : "py-2"
                    }`}
                  >
                    {item.quantity}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Distribution;
