import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cuffiIcon from "../../assets/CUFFI.svg";
import hiddenEye from "../../assets/hide.png";
import openedEye from "../../assets/show.png";

export default function LoginForm() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [toggle, setToggle] = useState(false);
  const validateForm = () => {
    const newErrors = {};
    if (!user) {
      newErrors.user = "Username is required";
    } else if (user !== "admin") {
      newErrors.user = "Invalid username";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password !== "cuffiadmin@007") {
      newErrors.password = "Invalid password";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      localStorage.setItem("authToken", "success");
      navigate("/admin");
    }
  };

  return (
    <div className="bg-login-bg bg-no-repeat bg-cover bg-center h-screen w-full flex items-center justify-center">
      <div className="bg-white p-6 md:p-12 grid   md:grid-cols-2 rounded-md shadow-md w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] ">
        <div className="md:w-[90%] flex justify-center items-center md:border-r-2 border-[#0E7570]">
          <img src={cuffiIcon} alt="cuffiIcon" />
        </div>
        <div className="py-6">
          <div className="flex flex-col items-center">
            <h2 className="text-[24px] md:text-[30px] font-semibold">
              Welcome
            </h2>
            <p className="text-[16px] md:text-[18px] font-light">
              Enter your login credentials
            </p>
          </div>
          <form onSubmit={handleSubmit} className="mt-6">
            <div>
              <input
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                className="mt-1 w-full py-3 px-5 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0E7570]"
                placeholder="Enter username"
              />
              {errors.user && (
                <p className="text-red-500 text-xs mt-1">{errors.user}</p>
              )}
            </div>

            <div className="mt-6">
              <div className="relative">
                <input
                  type={toggle ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className=" mt-1 w-full py-3 px-5 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0E7570]"
                  placeholder="Enter  password"
                />
                {toggle ? (
                  <img
                    className="absolute top-[32%] cursor-pointer  right-3"
                    src={openedEye}
                    onClick={() => setToggle(!toggle)}
                  />
                ) : (
                  <img
                    className=" absolute top-[32%] cursor-pointer right-3"
                    src={hiddenEye}
                    onClick={() => setToggle(!toggle)}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              )}
            </div>
            <div className="w-full flex justify-center mt-10">
              <button
                type="submit"
                className="w-full  bg-[#0E7570] hover:bg-[#004442] text-white p-2 rounded-md transition duration-200"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
