import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Table from "../../component/Table";
import Pagination from "../../component/Pagination";
import { distributeRewards, getRewards } from "../../service.js/Rewards";
import toast from "react-hot-toast";

const Rewards = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);
  const tableHead = [
    "Address",
    "Amount",
    "Investment Date",
    "Locking Period",
    "Reward",
    "Total Rewards",
  ];

  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error: queryError,
  } = useQuery({
    queryKey: ["Rewards", page],
    queryFn: () => getRewards(page, limit),
    onError: (error) => {
      console.error("Failed to fetch transactions:", queryError.message);
      setError(queryError.message);
      toast.error(queryError.message);
    },
  });

  const { mutate } = useMutation({
    mutationFn: async ({ totalRewards }) => {
      return await distributeRewards({ totalRewards });
    },
    onSuccess: (data) => {
      console.log(data, "rewards");
      setPage(1);
      setError(null);
      toast.success(`distributed successfully`, {
        duration: 5000,
      });
      setInputValue("");
      queryClient.invalidateQueries(["Rewards"]);
    },
    onError: (error) => {
      console.error("Error releasing token:", error.message || error);
      toast.error(error.message);
    },
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setInputValue(value);
    } else {
      toast.error("Please provide numeric value");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (inputValue !== "") {
      mutate({ totalRewards: inputValue });
    } else {
      toast.error("Please provide a valid rewards");
    }
    setSearchQuery(inputValue);
  };

  return (
    <div className="bg-white">
      <div className="bg-[#FFFFFF] p-2 pt-4 sm:p-8 shadow-custom-light rounded-[10px]">
        <form className="flex  justify-end" onSubmit={handleSearch}>
          <div className="flex w-full sm:w-[400px] justify-between h-[45px] border-2 border-[#0E7570]  rounded-md">
            <input
              className="pr-3 outline-none border-none pl-4 rounded-md"
              placeholder="Enter rewards..."
              value={inputValue}
              onChange={onChangeHandler}
            />
            <button
              type="submit"
              className="h-full px-2 sm:px-4 flex items-center  text-[white] justify-center bg-[#0E7570] cursor-pointer font-semibold text-[16px]"
            >
              Distribute
            </button>
          </div>
        </form>

        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-center text-gray-500 py-2">
            <thead className="text-[16px] font-medium leading-4 bg-[#9999A11A] text-[#66666E]">
              <tr>
                {tableHead.map((head, index) => (
                  <th key={index} className="py-4 px-2 min-h-[26px]">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.rewards?.map((transaction, index) => (
                <tr
                  key={transaction._id}
                  className="bg-white text-[#0A0A0A] transition duration-500 ease-in-out opacity-0 animate-fadeIn"
                >
                  <td className="p-3">
                    <a
                      href={`https://www.oklink.com/amoy/address/${transaction.user}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="relative text-blue-500 underline hover:text-blue-700 group"
                    >
                      {`${transaction.user.substring(
                        0,
                        6
                      )}...${transaction.user.substring(
                        transaction.user.length - 6
                      )}`}
                      <span className="absolute left-[110%] top-[30%] w-[130px] mb-2 hidden group-hover:block bg-black text-white text-sm px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        View on explorer
                      </span>
                    </a>
                  </td>
                  <td className="p-3">
                    {(transaction.lockedAmount / 100).toFixed(2)}
                  </td>
                  <td>
                    {new Date(transaction.createdAt).toLocaleString("en-GB", {
                      dateStyle: "short",
                      timeZone: "UTC",
                    })}
                  </td>
                  <td className="p-3">
                    {Math.ceil(transaction.lockingPeriod)} days
                  </td>
                  <td className="p-3">{transaction.reward}</td>
                  <td className="p-3">{transaction.totalRewards}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="w-full h-[400px] flex justify-center items-center">
              <div className="w-10 h-10 border-4 border-t-4 border-gray-200 rounded-full animate-spin border-t-[#d9dbdb]"></div>
            </div>
          )}

          {isError && (
            <div className="w-full h-[400px] flex justify-center items-center text-red-600">
              {queryError.message}
            </div>
          )}
          {!isLoading && !isError && data?.length === 0 && (
            <div className="w-full h-[400px] flex justify-center items-center text-gray-500">
              <p>No transaction found</p>
            </div>
          )}
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          totalPage={data?.meta?.totalPages}
        />
      </div>
    </div>
  );
};

export default Rewards;
