import React from "react";
import { Link } from "react-router-dom";

const NavItem = ({
  link,
  name,
  activeNavName,
  iconWhite,
  iconBlack,
  title,
  toggle,
  setToggle,
}) => {
  return (
    <>
      <Link
        to={link}
        className={`${
          name.some((n) => n === activeNavName)
            ? "font-semibold  text-[#FFFFFF] bg-[#0E7570] transition-all  rounded-[10px]"
            : "font-semibold text-[#66666E]"
        } flex items-center  gap-2 sm:gap-x-4 pl-5 sm:pl-6 pr-14 xl:pr-20 py-2 text-lg h-12`}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {name.some((n) => n === activeNavName) ? iconWhite : iconBlack}
        <span>{title}</span>
      </Link>
    </>
  );
};

export default NavItem;
