import React from "react";

const RewardsIcon = ({ color = "#66666E" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C8.89875 3 6.375 5.52375 6.375 8.625C6.375 11.7262 8.89875 14.25 12 14.25C15.1012 14.25 17.625 11.7262 17.625 8.625C17.625 5.52375 15.1012 3 12 3ZM12 13.5C9.31125 13.5 7.125 11.3138 7.125 8.625C7.125 5.93625 9.31125 3.75 12 3.75C14.6888 3.75 16.875 5.93625 16.875 8.625C16.875 11.3138 14.6888 13.5 12 13.5Z"
        fill={color}
      />
      <path
        d="M14.6809 7.26L13.0646 7.0125L12.3371 5.4675C12.2734 5.33625 12.1421 5.25 11.9959 5.25C11.8496 5.25 11.7184 5.3325 11.6546 5.4675L10.9271 7.0125L9.31086 7.26C9.17211 7.2825 9.05586 7.38 9.01086 7.51125C8.96586 7.6425 8.99961 7.7925 9.09711 7.89375L10.2784 9.105L9.99711 10.8188C9.97461 10.9613 10.0346 11.1037 10.1509 11.1862C10.2671 11.2688 10.4246 11.2762 10.5484 11.2087L11.9884 10.4138L13.4284 11.2087C13.4846 11.2387 13.5484 11.2537 13.6084 11.2537C13.6834 11.2537 13.7584 11.2312 13.8259 11.1862C13.9421 11.1037 14.0021 10.9613 13.9796 10.8188L13.6984 9.105L14.8796 7.89375C14.9771 7.7925 15.0109 7.64625 14.9659 7.51125C14.9209 7.37625 14.8046 7.2825 14.6659 7.26H14.6809ZM13.0421 8.715C12.9596 8.80125 12.9221 8.92125 12.9409 9.0375L13.1284 10.1737L12.1834 9.64875C12.1271 9.61875 12.0634 9.60375 12.0034 9.60375C11.9434 9.60375 11.8796 9.61875 11.8234 9.64875L10.8784 10.1737L11.0659 9.0375C11.0846 8.92125 11.0471 8.80125 10.9646 8.715L10.1621 7.89375L11.2534 7.725C11.3771 7.70625 11.4821 7.6275 11.5346 7.515L12.0071 6.51L12.4796 7.515C12.5321 7.6275 12.6371 7.70625 12.7609 7.725L13.8521 7.89375L13.0496 8.715H13.0421Z"
        fill={color}
      />
      <path
        d="M20.7672 20.8236C20.8872 20.6998 20.9097 20.5123 20.8234 20.3661L17.2759 14.4523C18.8697 13.0086 19.8784 10.9348 19.8784 8.62109C19.8784 4.27859 16.3459 0.746094 12.0034 0.746094C7.66094 0.746094 4.12469 4.28234 4.12469 8.62484C4.12469 10.9386 5.13344 13.0161 6.73094 14.4598L3.28094 20.4373C3.19469 20.5873 3.22094 20.7748 3.34469 20.8948C3.46844 21.0148 3.65594 21.0373 3.80219 20.9473L7.22219 18.8586L7.12469 22.8711C7.12094 23.0436 7.23344 23.1936 7.39844 23.2423C7.43219 23.2536 7.46594 23.2573 7.49969 23.2573C7.63094 23.2573 7.75469 23.1898 7.82594 23.0698L11.6247 16.4886C11.7484 16.4961 11.8759 16.5073 11.9997 16.5073C12.1234 16.5073 12.2472 16.4961 12.3709 16.4886L16.3234 23.0736C16.3909 23.1898 16.5147 23.2573 16.6459 23.2573C16.6834 23.2573 16.7172 23.2536 16.7547 23.2423C16.9197 23.1936 17.0284 23.0398 17.0209 22.8673L16.8559 18.8586L20.3172 20.8911C20.4634 20.9773 20.6509 20.9548 20.7747 20.8311L20.7672 20.8236ZM7.90844 21.4161L7.98719 18.1873C7.98719 18.0486 7.91969 17.9211 7.79969 17.8536C7.67969 17.7861 7.53344 17.7861 7.41719 17.8573L4.66469 19.5373L7.31969 14.9398C8.32469 15.6861 9.51344 16.1998 10.8072 16.3986L7.91219 21.4123L7.90844 21.4161ZM4.87469 8.62484C4.87469 4.69484 8.06969 1.49984 11.9997 1.49984C15.9297 1.49984 19.1247 4.69484 19.1247 8.62484C19.1247 12.5548 15.9297 15.7498 11.9997 15.7498C8.06969 15.7498 4.87469 12.5548 4.87469 8.62484ZM16.6384 17.8573C16.5184 17.7898 16.3722 17.7898 16.2559 17.8573C16.1397 17.9286 16.0684 18.0561 16.0759 18.1948L16.2109 21.4198L13.1997 16.3986C14.4934 16.1998 15.6884 15.6861 16.6934 14.9398L19.3684 19.5373L16.6384 17.8573Z"
        fill={color}
      />
    </svg>
  );
};

export default RewardsIcon;
