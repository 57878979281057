import React, { useEffect, useState } from "react";
import cuffi from "../../assets/CUFFI.svg";
import {
  Link,
  useNavigate,
  useParams,
  Outlet,
  useLocation,
} from "react-router-dom";
import transactionWhite from "../../assets/transaction-white.svg";
import transactionGray from "../../assets/transaction-gray.svg";
import distributionWhite from "../../assets/distribution-white.svg";
import distributionGray from "../../assets/distribution-gray.svg";
import logout from "../../assets/logOut.svg";
import bell from "../../assets/bell.svg";
import menuIcon from "../../assets/Menu.svg";
import greenWallet from "../../assets/green-wallet.svg";
import NavItem from "../NavItem";
import { getBalance } from "../../service.js/GetAdminBalance";
import { useQuery } from "@tanstack/react-query";
import TransactionIcon from "../../svg/AllTransaction";
import RewardsIcon from "../../svg/Rewards";
import DistributionIcon from "../../svg/Distribution";
import leftArrow from "../../assets/backward.svg";

const AdminLayout = () => {
  const location = useLocation();
  const [walletToggle, setWalletToggle] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    const currentWidth = window.innerWidth;
    setWindowWidth(currentWidth);

    if (currentWidth > 768) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ["balance"],
    queryFn: () => getBalance(),
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (data?.balance) {
      setWalletBalance(data.balance);
      console.log("Fetched balance:", data.balance);
    }
  }, [data]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [activeNavName, setActiveNavName] = useState("/admin");

  useEffect(() => {
    if (location.pathname) {
      setActiveNavName(location.pathname);
    }
  }, [location.pathname]);

  const NavItems = [
    {
      link: "/admin",
      title: "Transaction",
      iconWhite: (
        <div>
          <TransactionIcon color="#FFFFFF" />
        </div>
      ),
      iconBlack: (
        <div>
          <TransactionIcon color="#66666E" />
        </div>
      ),
      name: [
        "/admin",
        "/admin/pendingTransaction",
        "/admin/confirmTransaction",
      ],
      activeNavName: activeNavName,
      setActiveNavName: setActiveNavName,
      toggle,
      setToggle,
    },
    {
      link: "distribution",
      title: "Distribution",
      iconWhite: (
        <div>
          <DistributionIcon color="#FFFFFF" />
        </div>
      ),
      iconBlack: (
        <div>
          <DistributionIcon color="#66666E" />
        </div>
      ),
      name: ["/admin/distribution"],
      activeNavName: activeNavName,
      setActiveNavName: setActiveNavName,
      toggle,
      setToggle,
    },
    {
      link: "rewards",
      title: "Rewards",
      iconWhite: (
        <div>
          <RewardsIcon color="#FFFFFF" />
        </div>
      ),
      iconBlack: (
        <div>
          <RewardsIcon color="#66666E" />
        </div>
      ),
      name: ["/admin/rewards"],
      activeNavName: activeNavName,
      setActiveNavName: setActiveNavName,
      toggle,
      setToggle,
    },
  ];

  const handleLogout = () => {
    localStorage.clear("authToken");
    navigate("/");
  };
  useEffect(() => {
    const flag = localStorage.getItem("authToken");
    if (!flag) {
      navigate("/");
    }
  }, []);

  return (
    <div className="relative h-screen grid grid-cols-1  md:grid-cols-[30%_70%] lg:grid-cols-[21%_79%] xl:grid-cols-[20%_80%] ">
      <div
        className={`${
          toggle && windowWidth < 768
            ? " absolute left-0 top-0 transition-all  w-full bg-transparent h-screen z-40"
            : windowWidth < 768
            ? "absolute left-[-100%] transition-all"
            : ""
        } `}
        onClick={() => setToggle(!toggle)}
      >
        <div
          className="relative bg-white h-screen w-[65%] sm:w-[40%] md:w-[100%] rounded-sm    md:z-0  flex flex-col justify-between items-center py-10 px-4 border-r-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="pb-3">
            <div className="w-full flex justify-center">
              <img src={cuffi} alt="cuffiIcon" />
            </div>

            <div className="mt-20">
              <ul className="flex flex-col gap-8 cursor-pointer w-[90%] m-auto">
                {NavItems.map((item, index) => (
                  <li key={index}>
                    <NavItem {...item} setToggle={setToggle} toggle={toggle} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <button
              onClick={handleLogout}
              className=" bg-[#EEEEF0] hover:bg-[#e3e3e8] text-[#646464] flex gap-6 pl-4 pr-10 sm:pr-20 py-2 rounded-[10px] border-[1px]"
            >
              <img src={logout} alt="logout" /> Logout
            </button>
          </div>
          <span
            className=" md:hidden absolute -right-[15px] top-[9%] bg-[#EEEEF0] w-[28px] h-[28px] rounded-full flex items-center justify-center"
            onClick={() => setToggle(!toggle)}
          >
            <img src={leftArrow} alt="close-icon" />
          </span>
        </div>
      </div>
      {/* 2div */}
      <div className="h-screen overflow-auto">
        <div className="sticky top-0 z-20 bg-white w-full h-20 grid grid-cols-[15%_68%_15%] gap-1 sm:flex sm:flex-row items-center border-b  sm:justify-between px-3 sm:px-6">
          <div className="block md:hidden" onClick={() => setToggle(!toggle)}>
            <img src={menuIcon} alt="menuIcon" />
          </div>
          <div className="flex justify-center">
            <button
              onMouseEnter={() => setWalletToggle(true)}
              onMouseLeave={() => setWalletToggle(false)}
              className={`flex  items-center w-[130px] sm:w-[200px] px-1 sm:px-2 md:px-4 py-2 gap-x-2 sm:gap-x-4 text-[#0E7570] font-semibold rounded-[6px] transition-all ${
                walletToggle
                  ? " bg-[#0E75701A] opacity-90 border-none"
                  : "border border-[#0E7570]"
              }`}
            >
              <img src={greenWallet} alt="greenWallet" />
              {walletToggle ? (
                <span className="opacity-100">{walletBalance}</span>
              ) : (
                <span className="text-[10px] sm:text-[16px]">
                  Locked Tokens
                </span>
              )}
            </button>
          </div>
          <div className="flex justify-center  gap-x-1 sm:gap-x-4">
            <img src={bell} alt="bell" />
          </div>
        </div>
        <div className="p-2 sm:p-4 lg:p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
