import React from "react";
import forward from "../assets/forward.svg";
import backward from "../assets/backward.svg";

const Pagination = ({ page, setPage, totalPage }) => {
  return (
    <div className="flex justify-end mr-5 sm:mr-10 mt-5 gap-2">
      {/* Previous Button */}
      <span
        className={`flex items-center py-1.5 px-3 border border-[#9999A1] rounded-[6px] text-[#1A1A1A] ${
          page < 2 ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
        onClick={page > 1 ? () => setPage(page - 1) : null}
      >
        <img src={backward} alt="backward" />
      </span>

      {/* Current Page */}
      <span className="px-3 py-1.5 border border-[#9999A1] rounded-[6px] bg-[#CDCDCD] text-[#1A1A1A]">
        {page}
      </span>

      {/* Next Button */}
      <span
        className={`flex items-center py- px-3 border border-[#9999A1] rounded-[6px] text-[#1A1A1A] ${
          page === totalPage
            ? "cursor-not-allowed opacity-50"
            : "cursor-pointer"
        }`}
        onClick={page < totalPage ? () => setPage(page + 1) : null}
      >
        <img src={forward} alt="forward" />
      </span>
    </div>
  );
};

export default Pagination;
