import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllTransaction } from "../../service.js/AllTransaction";
import Pagination from "../../component/Pagination";
import ConfirmRelease from "../../component/Pop-Up/ConfirmRelease";
import copyIcon from "../../assets/copy.svg";
import toast from "react-hot-toast";

const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

const AllTransaction = () => {
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const limit = 10;

  const tableHead = [
    "Investor's Address",
    "Value",
    "Start Date",
    "Locking Period",
    "Release Date",
  ];

  const { data, isLoading, isError, refetch, error } = useQuery({
    queryKey: ["AllTransaction", searchQuery, page],
    queryFn: () => getAllTransaction(searchQuery, page, limit),
    onError: (error) => {
      toast.error(error.message || "Failed to fetch transactions.");
      console.error("Query Error:", error);
    },
  });
  const formatDate = (dateString = "") => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    } else return "";
  };

  useEffect(() => {
    if (data) {
      console.log("Fetched data:", data);
    }
  }, [data]);

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(inputValue);
    setPage(1);
  };

  const copyToClipboard = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success("Address copied to clipboard!");
        setInputValue(address);
      })
      .catch(() => toast.error("Failed to copy address."));
  };
  const transactions = data?.transactions || [];
  const { totalItems, totalPages } = data?.meta || {};
  return (
    <div className="bg-white">
      <div className="bg-[#FFFFFF] py-4  p-2 sm:p-8 shadow-custom-light rounded-[10px]">
        <form className="flex w-full justify-end" onSubmit={handleSearch}>
          <div className="flex w-full sm:w-[400px] h-[45px] justify-between border-2 border-[#0E7570] items-center rounded-md">
            <input
              className="w-full pr-4 outline-none border-none pl-4 rounded-md"
              placeholder="Search by address..."
              value={inputValue}
              onChange={onChangeHandler}
            />
            <button
              type="submit"
              className="h-full w-auto px-3 sm:px-4 flex items-center  text-[white] justify-center bg-[#0E7570] cursor-pointer font-semibold text-[16px]"
            >
              Search
            </button>
          </div>
        </form>

        <div className="relative overflow-auto mt-4">
          <table className="w-full text-sm text-center">
            <thead className="text-[16px] font-medium leading-4 bg-[#9999A11A] text-[#66666E]">
              <tr className="rounded-[4px]">
                {tableHead.map((eachHead, index) => (
                  <th key={index} scope="col" className="p-4 min-h-[26px]">
                    {eachHead}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {transactions.map((transaction, index) => (
                <tr
                  key={transaction._id}
                  className="bg-white  text-[#0A0A0A] transition duration-500 ease-in-out opacity-0 animate-fadeIn"
                >
                  <td className="p-3 transition">
                    <div className="flex justify-center gap-2">
                      <a
                        href={`https://www.oklink.com/amoy/address/${transaction.data.user}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative text-blue-500 underline hover:text-blue-700 group"
                      >
                        {shortenAddress(transaction.data.user)}

                        <span className="absolute left-[125%] top-[30%] w-[130px] mb-2 hidden group-hover:block bg-black text-white text-sm px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          View on explorer
                        </span>
                      </a>
                      <img
                        src={copyIcon}
                        alt="copyIcon"
                        className="cursor-pointer"
                        onClick={() => copyToClipboard(transaction.data.user)}
                      />
                    </div>
                  </td>
                  <td className="p-3">
                    {(transaction.data.amount / 100).toFixed(2)}
                  </td>
                  <td className="p-3">
                    {transaction ? formatDate(transaction.data?.startDate) : ""}
                  </td>
                  <td className="p-3">{transaction.data.lockingPeriod} days</td>
                  <td className="p-3">
                    {transaction
                      ? formatDate(transaction.data?.releaseDate)
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="w-full h-[400px] flex justify-center items-center">
              <div className="w-10 h-10 border-4 border-t-4 border-gray-200  rounded-full animate-spin border-t-[#d9dbdb]"></div>
            </div>
          )}
          {isError && (
            <div className="w-full h-[400px] flex justify-center items-center text-red-600">
              {error?.message || "An unexpected error occurred."}
            </div>
          )}
          {!isLoading && !isError && data?.length === 0 && (
            <div className="w-full h-[400px] flex justify-center items-center text-gray-500">
              <p>No transaction found</p>
            </div>
          )}
          {/* {selectedTransaction && (
            <ConfirmRelease
              alert={alert}
              setAlert={setAlert}
              address={selectedTransaction.data.user}
              value={selectedTransaction.data.amount}
               id={selectedTransaction._id}
            />
          )} */}
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          totalPage={totalPages}
        />
      </div>
    </div>
  );
};

export default AllTransaction;
