import React, { useState } from "react";
import Pagination from "../component/Pagination";

const Table = ({ tableHead = [], tableData = [] }) => {
  return (
    <div className="bg-[#FFFFFF] p-2 sm:p-8 shadow-custom rounded-[10px] ">
      <div className="relative overflow-x-auto mt-4 ">
        <table className="w-full text-sm text-center text-gray-500 py-2">
          <thead className="text-[16px] font-medium leading-4 bg-[#9999A11A] text-[#66666E] ">
            <tr className="rounded-[4px]">
              {tableHead.map((eachHead) => (
                <th scope="col" className="p-2 min-h-[26px]">
                  {eachHead}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default Table;
