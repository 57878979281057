import React from "react";

const TransactionIcon = ({ color = "#66666E" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_396_15)">
      <path
        d="M0.75 12C0.75 14.225 1.4098 16.4001 2.64597 18.2502C3.88213 20.1002 5.63914 21.5422 7.69481 22.3936C9.75048 23.2451 12.0125 23.4679 14.1948 23.0338C16.3771 22.5997 18.3816 21.5283 19.955 19.9549C21.5283 18.3816 22.5998 16.377 23.0338 14.1948C23.4679 12.0125 23.2451 9.75047 22.3936 7.69481C21.5422 5.63914 20.1002 3.88213 18.2502 2.64596C16.4001 1.4098 14.225 0.749994 12 0.749994C9.01631 0.749994 6.15483 1.93526 4.04505 4.04504C1.93526 6.15483 0.75 9.01631 0.75 12ZM21.75 12C21.75 13.9284 21.1782 15.8134 20.1068 17.4168C19.0355 19.0202 17.5127 20.2699 15.7312 21.0078C13.9496 21.7458 11.9892 21.9389 10.0979 21.5627C8.20655 21.1864 6.46927 20.2578 5.10571 18.8943C3.74215 17.5307 2.81355 15.7934 2.43734 13.9021C2.06114 12.0108 2.25422 10.0504 2.99217 8.26883C3.73013 6.48725 4.97981 4.96451 6.58319 3.89317C8.18657 2.82182 10.0716 2.24999 12 2.24999C14.5859 2.24999 17.0658 3.27722 18.8943 5.1057C20.7228 6.93418 21.75 9.41413 21.75 12Z"
        fill={color}
      />
      <path
        d="M5.31 13.215C5.2535 13.3523 5.23902 13.5033 5.26839 13.6488C5.29777 13.7944 5.36967 13.9279 5.475 14.0325L8.475 17.0325C8.61848 17.1554 8.80303 17.2196 8.99179 17.2123C9.18055 17.205 9.3596 17.1268 9.49317 16.9932C9.62674 16.8596 9.70499 16.6806 9.71228 16.4918C9.71957 16.3031 9.65537 16.1185 9.5325 15.975L7.8075 14.25H18C18.1989 14.25 18.3897 14.171 18.5303 14.0304C18.671 13.8897 18.75 13.699 18.75 13.5C18.75 13.3011 18.671 13.1104 18.5303 12.9697C18.3897 12.8291 18.1989 12.75 18 12.75H6C5.85193 12.7508 5.70739 12.7953 5.5846 12.8781C5.46181 12.9608 5.36627 13.0781 5.31 13.215ZM14.4675 8.03255L16.1925 9.75005H6C5.80109 9.75005 5.61032 9.82907 5.46967 9.96972C5.32902 10.1104 5.25 10.3011 5.25 10.5C5.25 10.699 5.32902 10.8897 5.46967 11.0304C5.61032 11.171 5.80109 11.25 6 11.25H18C18.149 11.2509 18.2948 11.2074 18.419 11.125C18.5431 11.0427 18.6399 10.9252 18.697 10.7877C18.7542 10.6501 18.769 10.4986 18.7397 10.3525C18.7104 10.2065 18.6383 10.0725 18.5325 9.96755L15.5325 6.96755C15.389 6.84468 15.2045 6.78047 15.0157 6.78777C14.827 6.79506 14.6479 6.8733 14.5143 7.00688C14.3808 7.14045 14.3025 7.3195 14.2952 7.50826C14.2879 7.69701 14.3521 7.88157 14.475 8.02505L14.4675 8.03255Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_396_15">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(0 -1 1 0 0 24)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TransactionIcon;
