import axios from "axios";

export const getBalance = async (
  address = process.env.REACT_APP_ADMIN_ADDRESS
) => {
  try {
    const response = await axios.post("https://backend.cuffi.shop/getBalance", {
      address: address,
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        throw new Error(
          error.response.data.message ||
            `Server responded with status: ${error.response.status}`
        );
      } else if (error.request) {
        console.error("Network Error: No response received.");
        throw new Error("Network error. Please check your connection.");
      } else {
        console.error("Request Error:", error.message);
        throw new Error("Failed to fetch transactions. Please try again.");
      }
    } else {
      console.error("Unexpected Error:", error);
      throw new Error("An unexpected error occurred.");
    }
  }
};
